import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import FullPageSection from '../components/FullPageSection'
import HeroTitle from '../components/HeroTitle'
import Footer from '../components/Footer'
import Header from '../components/Header'

import bgImage from '../img/TC3.jpeg'
import tlcImage from '../img/about.png'

const AboutPage = (props) => (
  <main>
    <Header className="bg-white" />

    <FullPageSection
      className="overlay-blue align-items-center d-flex splash"
      bgImage={bgImage}
    >
      <Container>
        <Row>
          <Col xs={12}>
            <HeroTitle
              className="mt-5 pt-5"
              textArray={[
                'Tiffany L. Cabán is a career public',
                'defender and the current Council Member',
                'for the 22nd District  in the New York City Council',
              ]}
            />
          </Col>
        </Row>
      </Container>
    </FullPageSection>
    <div className="mt-3 pt-5 pb-5 mb-5">
      <Container>
        <Row>
          <Col md={6} className="position-relative">
            <img src={tlcImage} className="w-100 mb-4" height="auto" />
          </Col>
          <Col md={6}>
            <section>
              <h2 className="mb-4">Meet Tiffany</h2>
              <div className="font-1">
                <p>
                  A Queens native, Tiffany was born in Richmond Hill, Queens, to
                  Puerto Rican parents who grew up in Woodside Houses. Before
                  joining the New York City Council, Tiffany worked as a public
                  defender, representing people who did not have resources to
                  defend themselves against the brutal system of mass
                  incarceration. In her years at New York County Defender
                  Services (NYCDS) and the Legal Aid Society’s Criminal Defense
                  Practice, Tiffany represented over a thousand indigent clients
                  in cases ranging from turnstile jumping to homicide.
                </p>
                <p>
                  Throughout her professional career, she has used the law to
                  help New York City’s most vulnerable communities, and her
                  experiences advocating on behalf of her clients helped her
                  identify some of the worst inequities produced and exacerbated
                  by our criminal legal system. The years she spent as a public
                  defender inspired her to run for elected office, determined to
                  redefine how our city approaches public health and public
                  safety.
                </p>
              </div>
            </section>
          </Col>
        </Row>
      </Container>
      <Container className="mt-3">
        <Row>
          <Col xs={12}>
            <section>
              <div className="font-1">
                <p>
                  Tiffany attended PS 62 in Richmond Hill and JHS 210 Elizabeth
                  Blackwell in Woodhaven, and later attended St. Francis
                  Preparatory High School in Fresh Meadows. Tiffany earned a
                  Bachelor’s degree in Crime, Law, and Justice from Pennsylvania
                  State University and a Juris Doctorate from New York Law
                  School. She lives in Astoria with her rescue dog, Natalie.
                </p>
              </div>
            </section>
          </Col>
        </Row>
      </Container>
    </div>
    <Footer className="mt-5" />
  </main>
)

export default AboutPage
