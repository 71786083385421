import React from 'react'
import { Link, graphql } from 'gatsby'

import PageLayout from '../layout/page'
import MetaSEO from '../components/MetaSEO'
import About from '../layout/AboutPage';


class AboutPage extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  formLoaded() {
  }

  render() {

    return (
      <PageLayout showBigCircle={false}>
        <MetaSEO title="About Tiffany" keywords={[`tiffany cabán`, `tiffany caban`, `caban for queens`, `caban`, `cabán`, `queens district attorney`, `district attorney`]} 
          url="https://www.cabanforqueens.com/about"
        />
        <About {...{...this.props.data.page}} />
      </PageLayout>
    );

  }
  
}

// <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
//   <Image />
// </div>
export default AboutPage;

export const AboutPageQuery = graphql`
  query aboutPageQuery {
    page: markdownRemark (frontmatter: { uniq: {eq: "about" }}) {
      html
      frontmatter {
        title
        subtitle
        spiel
      }
    }
  }

`;
